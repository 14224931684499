// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: underline;
  color: darkslategrey;
}

.loading_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
