// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';

.root {
  @include font-defaults;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  :global(.ant-steps-item-title) {
    font-size: 14px;
  }

  :global(.ant-steps-item-icon) {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.submit_button {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
