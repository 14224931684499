// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.hotel_policy {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.action_link {
  display: inline-flex;
  text-align: right;
  align-items: center;
  color: $tbf-blue;
  cursor: pointer;
  padding-left: 10px;
}

.create_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $tbf-blue;
  cursor: pointer;
  margin-bottom: 25px;
}