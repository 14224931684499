@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  :global(.ant-upload-list-picture-card) {
    width: 100%;
  }

  :global(.ant-upload-list-picture-card-container) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
    min-width: 150px;
    max-width: 200px;
    height: 150px;
  }

  :global(.ant-upload-list-picture-card) {
    ::before {
      width: 100%;
    }
  }

  :global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    max-width: 250px;
    min-width: 200px;
    min-height: 150px;
  }

  :global(.ant-upload-list.ant-upload-list-picture-card) {
    display: flex;
    flex-wrap: wrap;
  }

  .fillColorContainer > div {
    display: flex;
    align-items: center;
  }
  
  .fillColor {
    height: 50px;
    width: 50px;
    padding: 0px;
    border: none;
    cursor: pointer;
  }

  .addPhoto {
    font-size: 20px;
  }

  .addNewPhoto {
    color: $tbf-blue;
  }

  .giftCardPhotoContainer {
    width: 100%;
  }

  .deletePhotoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: $tbf-white;
    color: $tbf-darkerGrey;
    font-size: 15px;
    position: absolute;
    top: 15px;
    right: 5px;
    cursor: pointer;
  }

  .giftCardPhoto {
    width: 100%;
    height: 150px;
    object-fit: contain;
  }
}
