// Generated with util/create-component.js
@import 'variables.scss';
@import 'typography.scss';

.root {
  @include font-defaults;

  :global(.ant-form-item) {
    margin-bottom: 3px;
  }

  :global(.ant-radio-button-wrapper) {
    width: 33.33%;
    text-align: center;
    text-transform: capitalize;
  }

  :global(.ant-radio-group) {
    width: 100%;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }

  :global(.ant-upload-list-picture-card) {
    width: 100%;
  }

  :global(.ant-upload-list-picture-card-container) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
    min-width: 150px;
    max-width: 200px;
    height: 150px;
  }

  :global(.ant-upload-list-picture-card) {
    ::before {
      width: 100%;
    }
  }

  :global(.ant-upload.ant-upload-select.ant-upload-select-picture-card) {
    width: 32%;
    text-align: center;
    justify-content: center;
    display: flex;
    max-width: 250px;
    min-width: 200px;
    min-height: 150px;
  }

  :global(.ant-upload-list.ant-upload-list-picture-card) {
    display: flex;
    flex-wrap: wrap;
  }

  :global(.ant-input-number-group-wrapper) {
    width: 100%;
  }

  :global(.ant-input-number.ant-input-number-in-form-item) {
    width: 100%;
  }

  :global(.ant-table-placeholder) {
    display: none;
  }
}

.gift_card_photo_container {
  width: 100%;
}

.delete_photo_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: $tbf-white;
  color: $tbf-darkerGrey;
  font-size: 15px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.gift_card_photo {
  width: 100%;
  height: 150px;
}

.add_photo {
  font-size: 20px;
}

.add-new_photo {
  color: $tbf-blue;
}

.switch {
  margin-bottom: 20px;
}

.submit_section {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full_width {
  width: 100%;
}

.switch_button {
  margin-right: 5px;
}

.spinner_container {
  height: 100vh;
  display: flex;
  align-items: center;
}

.expiration_duration {
  width: 50%;
}

.expiration_duration_unit {
  width: 50%;
}
