// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  padding-bottom: 40px;

  :global(.ant-form-item) {
    margin-bottom: 3px;
  }
  
  :global(.ant-radio-button-wrapper) {
    width: 50%;
    text-align: center;
    text-transform: capitalize;
  }
  
  :global(.ant-radio-group) {
    width: 100%;
  }

  :global(.ant-select-item-group),
  :global(.ant-form-item-label > label) {
    text-transform: capitalize;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.loading_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save_button {
  margin-left: 20px;
}